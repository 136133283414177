
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    
import React from 'react'
import App from 'next/app'
import PropTypes from 'prop-types'
import dynamic from 'next/dynamic'
import '../assets/scss/global.scss'
import { endTime, startTime } from '@shared/libs/metrics'
import { setHeaderMenu, setHeaderSidebarMenu } from '@shared-submodule/sm-libs/menu'
import InterstitialAd from '@shared/components/ads/interstitialAd'
// import useWindowSize from '@shared/hooks/windowSize'
// import PopUpAdd from '@shared/components/popUpAdd'
// import useFirebase from '@shared/hooks/useFirebase'

const AppLayout = dynamic(() => import('@shared/components/layout/app'))

function MyApp({ Component, pageProps, headerMenu, headerSidebarMenu }) {
  setHeaderMenu(headerMenu)
  // setFooterMenu(footerMenu)
  setHeaderSidebarMenu(headerSidebarMenu)
  // const [width] = useWindowSize()
  // if (typeof window !== 'undefined') {
  //   useFirebase()
  // }
  return (
    <AppLayout>
      {/* <PopUpAdd/> */}
      {<InterstitialAd />}
      <Component {...pageProps} />
    </AppLayout>
  )
}
MyApp.propTypes = {
  Component: PropTypes.any,
  pageProps: PropTypes.any,
  headerMenu: PropTypes.array,
  footerMenu: PropTypes.array,
  headerSidebarMenu: PropTypes.array,
  token: PropTypes.string
}

MyApp.getInitialProps = async (appContext) => {
  const appProps = await App.getInitialProps(appContext)
  try {
    const getMenus = (await import('@shared/libs/commonApi')).commonAPIForMenu
    startTime('getMenus')
    const allMenus = await getMenus()
    const timeTaken = endTime('getMenus')
    appProps.perf = timeTaken
    appProps.headerMenu = allMenus.headerMenu
    appProps.headerSidebarMenu = allMenus.headerSidebarMenu
    return appProps
  } catch (e) {
    console.error(e)
    return { ...appProps, headerMenu: [], headerSidebarMenu: [] }
  }
}

const __Page_Next_Translate__ = MyApp

// export function reportWebVitals(metric) {
//   console.log(metric)
// }


    export default __appWithI18n(__Page_Next_Translate__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: false,
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
    });
  