let headerMenu
let footerMenu
let headerSidebarMenu
let isPreviewMode = false
let currentUser
let miniScorecard

export const setHeaderMenu = (data) => {
  headerMenu = data
}

export const setMiniScoreCard = (data) => {
  miniScorecard = data
}

export const getMiniScoreCard = () => miniScorecard

export const getHeaderMenu = () => headerMenu

export const setFooterMenu = (data) => {
  footerMenu = data
}

export const getFooterMenu = () => footerMenu

export const setHeaderSidebarMenu = (data) => {
  headerSidebarMenu = data
}

export const getHeaderSidebarMenu = () => headerSidebarMenu

export const setPreviewMode = (data) => {
  isPreviewMode = data
}

export const getPreviewMode = () => isPreviewMode
/**
 * set logged in user detail
 * @param {Object} data
 */
export const setCurrentUser = (data) => {
  currentUser = data
}
/**
 * get logged in user detail
 * @return {Object}
 */
export const getCurrentUser = () => currentUser
