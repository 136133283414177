const colours = {
  reset: '\x1b[0m',
  bright: '\x1b[1m',
  dim: '\x1b[2m',
  underscore: '\x1b[4m',
  blink: '\x1b[5m',
  reverse: '\x1b[7m',
  hidden: '\x1b[8m',

  fg: {
    black: '\x1b[30m',
    red: '\x1b[31m',
    green: '\x1b[32m',
    yellow: '\x1b[33m',
    blue: '\x1b[34m',
    magenta: '\x1b[35m',
    cyan: '\x1b[36m',
    white: '\x1b[37m',
    gray: '\x1b[90m',
    crimson: '\x1b[38m' // Scarlet
  },
  bg: {
    black: '\x1b[40m',
    red: '\x1b[41m',
    green: '\x1b[42m',
    yellow: '\x1b[43m',
    blue: '\x1b[44m',
    magenta: '\x1b[45m',
    cyan: '\x1b[46m',
    white: '\x1b[47m',
    gray: '\x1b[100m',
    crimson: '\x1b[48m'
  }
}

/**
 *
 *
 * @export
 * @param prop
 * @param options {{ fg: string, bg: string }}
 */
export function log(prop, options) {
  console.log(colours.bg?.[options?.bg || 'white'], colours.fg?.[options?.fg || 'white'], prop, colours.reset)
}

const time = new Map()
let totalTime = 0

export function execTime() {
  log(totalTime)
  const _totalTime = totalTime
  totalTime = 0
  return _totalTime
}

export function startTime(key) {
  const data = { stamp: new Date().getTime(), key }
  time.set(key, data)
  return data
}

export function endTime(key, clear) {
  const timeTaken = (new Date().getTime() - time.get(key)?.stamp).toFixed(2)
  totalTime += Number(timeTaken)
  time.delete(key)
  log({ data: `${key} ~ ${timeTaken} ms` }, { bg: 'blue', fg: 'black' })
  return `${key} ~ ${timeTaken} ms`
}
